import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  {
    pages: allSanityPage {
      nodes {
        _id
        slug {
          current
        }
      }
    }
    posts: allSanityPost {
      nodes {
        _id
        slug {
          current
        }
      }
    }
  }
`

const InternalLink = ({ to, ...props }) => {
  const { pages, posts } = useStaticQuery(query)
  const page = [...pages.nodes, ...posts.nodes].find(page => page._id === to)
  return page ? (
    <Link to={`/${page.slug.current}/`} {...props} />
  ) : (
    props.children
  )
}

export default InternalLink
