import React from 'react'

import BannerModule from './BannerModule'
import TitleModule from './TitleModule'
import TextModule from './TextModule'

const modules = {
  bannerModule: BannerModule,
  titleModule: TitleModule,
  textModule: TextModule,
}

const Module = props => {
  const module = modules[props.module._type]
  if (!module) {
    console.error(`No module of type ${props.module._type} exists`)
    return null
  }
  return React.createElement(module, props)
}

// const BlockModule = ({ module, first }) => {
//   return (
//     <Module module={module} first={first}>
//       {module.body && (
//         <BlockContent blocks={module.body} align={module.align} />
//       )}
//     </Module>
//   )
// }

export default Module
