import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { theme, withProp } from 'styled-tools'
import { rem, tint } from 'polished'

import Logo from '../Logo'
import Block from '../Block'
import { Container, Row, Column } from '../Grid'
import { media } from '../../theme'
import InternalLink from '../InternalLink'

import SocialWrapper from './SocialWrapper'

const Wrapper = styled(Block).attrs(() => ({
  component: 'footer',
  type: 'dark',
}))`
  background: ${theme('colors.primaryDark')};
  color: ${theme('colors.mediumLight')};
  padding-bottom: ${rem(56)};
  ${media('sm')`
    text-align: left;
  `}
  a {
    color: inherit;
  }
  ${Container} {
    mix-blend-mode: color-dodge;
  }

`

const StyledLogo = styled(Logo).attrs(() => ({ inverted: true }))`
  width: ${rem(100)};
  margin: ${rem(48)} auto ${rem(32)};
  opacity: 100%;
  filter: brightness(0.5);
`

const Title = styled.h4`
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-weight: 400;
`

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: ${rem(12)} 0 ${rem(48)};
`

const ListItem = styled.li`
  line-height: 1.3;
  margin-bottom: ${rem(8)};
`

const Address = styled.address`
  text-align: center;
  > :nth-child(2) {
    margin-bottom: ${rem(8)};
  }
  p {
    margin: ${rem(2)} 0;
    font-size: ${rem(12)};
  }
`

const query = graphql`
  {
    sanityFooter {
      sitemap {
        title
        items {
          _id
          title
        }
      }
    }
  }
`

const Footer = () => {
  const { sanityFooter: footer } = useStaticQuery(query)
  return (
    <Wrapper>
      <Row>
        {footer?.sitemap?.map(category => (
          <Column key={category.title} sm={1 / footer.sitemap.length}>
            <Title>{category.title}</Title>
            <List>
              {category.items.map(item => (
                <ListItem key={item._id}>
                  <InternalLink to={item._id}>{item.title}</InternalLink>
                </ListItem>
              ))}
            </List>
          </Column>
        ))}
      </Row>
      <StyledLogo />
    </Wrapper>
  )
}

export default Footer
