import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const query = graphql`
  query Images {
    images: allSanityImageAsset {
      nodes {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Image = ({ image, ...props }) => {
  const data = useStaticQuery(query)
  const getImage = id => {
    const img = data.images.nodes.find(node => node.id === id)
    return img ? img.localFile.childImageSharp : null
  }
  const imageProps = getImage(image)
  return imageProps ? (
    <Img {...imageProps} {...props} />
  ) : null
}

export default Image
