import React from 'react'
import styled from 'styled-components'
import src from '../../images/logo.png'
export { default as Social } from './Social'

const Logo = ({ className }) => <img className={className} src={src} />

export default styled(Logo)`
  display: block;
  opacity: 50%;
`
