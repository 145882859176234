import React, { useRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { withProp, theme, ifProp } from 'styled-tools'
import { rem, transparentize } from 'polished'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import { media } from '../../theme'
import InternalLink from '../InternalLink'

const Wrapper = styled.ul`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;

  transform: ${ifProp('open', 'translateX(0)', 'translateX(100%)')};
  transition: 150ms linear transform;

  background: ${withProp('theme.colors.dark', transparentize(0.1))};
  list-style: none;
  padding: ${rem(94)} 0 0;
  margin: 0;
  text-align: left;
  li {
    margin-bottom: 0;
    border-bottom: 1px solid ${theme('colors.medium')};
    &:first-child {
      border-top: 1px solid ${theme('colors.medium')};
    }
    ${media('md')`
      &, &:first-child {
        border: none;
      }
      &:last-child a {
        padding-right: 0;
      }
    `}

    a {
      color: inherit;
      text-transform: uppercase;
      letter-spacing: 0.07em;
      display: block;
      padding: ${rem(16)} ${rem(16)};
      &:hover {
        text-decoration: none;
        color: ${theme('colors.white')};
      }
    }
  }

  button {
    font-size: 80%;
  }

  ${media('sm')`
    padding-top: ${rem(120)};
  `}

  ${media('md')`
    margin-top: ${rem(120)};
    padding: 0;
  `}

  ${media('md')`
    transform: none;
    background: transparent;
    position: absolute;
    left: initial;
    right: 0;
    height: ${rem(100)};
    width: auto;
    margin-top: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${theme('colors.light')};
  `}
`

const query = graphql`
  {
    sanityHeader {
      menuItems {
        _id
        title
      }
    }
  }
`

const Menu = ({ open }) => {
  const target = useRef()
  useEffect(() => {
    if (open) {
      disableBodyScroll(target.current)
    } else {
      enableBodyScroll(target.current)
    }
    return () => clearAllBodyScrollLocks()
  })
  const data = useStaticQuery(query)
  return (
    <Wrapper open={open} ref={target}>
      {data.sanityHeader?.menuItems?.map(item => (
        <li key={item._id}>
          <InternalLink to={item._id}>{item.title}</InternalLink>
        </li>
      ))}
    </Wrapper>
  )
}

export default Menu
