import { rem, tint } from 'polished'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  teal: '#2A506D',
  orange: '#AA7A3A',
  brown: '#843c16',
  darkBrown: '#2f180c',
}

const contextualColors = {
  primary: colors.brown,
  primaryDark: colors.darkBrown,
  secondary: colors.orange,
  primaryText: tint(0.15, colors.black),
  dark: tint(0.15, colors.black),
  medium: tint(0.5, colors.black),
  mediumLight: tint(0.8, colors.black),
  light: tint(0.95, colors.black),
  error: colors.red,
}

const theme = {
  typography: {
    fontFamily: `Open Sans, sans-serif`,
    fontSize: rem(14),
  },
  sizes: {
    sm: '100%',
    md: '1024px',
    lg: '1024px',
  },
  breakpoints: {
    sm: '640px',
    md: '1024px',
    lg: '1280px',
  },
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
