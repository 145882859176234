import { css } from 'styled-components'
import { theme } from 'styled-tools'

const colorsByType = type => {
  if (!type) {
    return
  }
  const bg = type
  const text = ['white', 'light'].includes(type) ? 'dark' : 'light'
  const link = ['white', 'light'].includes(type) ? 'primary' : 'light'

  const h1 = ['white', 'light'].includes(type) ? 'primary' : 'light'
  const h2 = ['white', 'light'].includes(type) ? 'primary' : 'light'
  const h3 = ['white', 'light'].includes(type) ? 'primary' : 'light'

  return css`
    background: ${theme(`colors.${bg}`)};
    color: ${theme(`colors.${text}`)};
    h1 {
      color: ${theme(`colors.${h1}`)};
    }
    h2 {
      color: ${theme(`colors.${h2}`)};
    }
    h3 {
      color: ${theme(`colors.${h3}`)};
    }
    h4 {
      color: ${theme(`colors.${h3}`)};
    }
    a {
      color: ${theme(`colors.${link}`)};
    }
  `
}

export default colorsByType
